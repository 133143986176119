@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./tailwind-components";

html,
body {
    height: 100%;
    @apply antialiased;
}

// Taiga UI - Pixacare theme
// Generated from TailwindCSS variables.
:root {

    /* Colors */
    --tui-background-base: theme(colors.white);
    --tui-background-base-alt: theme(colors.slate.50);

    --tui-background-neutral-1: theme(colors.slate.100);
    --tui-background-neutral-1-hover: theme(colors.slate.200);
    --tui-background-neutral-1-pressed: theme(colors.slate.100);

    --tui-background-neutral-2: theme(colors.blue.100);
    --tui-background-neutral-2-hover: theme(colors.blue.200);
    --tui-background-neutral-2-pressed: theme(colors.blue.100);

    --tui-background-accent-1: theme(colors.blue.800);
    --tui-background-accent-1-hover: theme(colors.blue.700);
    --tui-background-accent-1-pressed: var(--tui-background-accent-1);

    --tui-background-accent-2: theme(colors.pixared-1);
    --tui-background-accent-2-hover: theme(colors.red.500);
    --tui-background-accent-2-pressed: theme(colors.red.700);

    --tui-background-accent-opposite-pressed: theme(colors.slate.700);
    --tui-background-accent-opposite-hover: theme(colors.slate.800);
    --tui-background-accent-opposite: theme(colors.slate.900);

    --tui-background-elevation-1: theme(colors.white);
    --tui-background-elevation-2: theme(colors.white);

    // Statuses

    --tui-status-negative: theme(colors.red.700);
    --tui-status-negative-pale: theme(colors.red.50);
    --tui-status-negative-pale-hover: theme(colors.red.100);

    --tui-status-positive: theme(colors.emerald.700);
    --tui-status-positive-pale: theme(colors.emerald.50);
    --tui-status-positive-pale-hover: theme(colors.emerald.100);

    --tui-status-warning: theme(colors.orange.600);
    --tui-status-warning-pale: theme(colors.orange.50);
    --tui-status-warning-pale-hover: theme(colors.orange.100);

    --tui-status-info: theme(colors.blue.600);
    --tui-status-info-pale: theme(colors.blue.100);
    --tui-status-info-pale-hover: theme(colors.blue.100);

    --tui-status-neutral: theme(colors.slate.500);

    // Texts : 
    --tui-text-primary: theme(colors.slate.900);
    --tui-text-secondary: theme(colors.slate.600);
    --tui-text-tertiary: theme(colors.slate.400);

    --tui-text-primary-on-accent-1: theme(colors.white);
    --tui-text-primary-on-accent-2: theme(colors.white);

    --tui-text-action: theme(colors.blue.900);
    --tui-text-action-hover: theme(colors.blue.800);

    --tui-text-positive: theme(colors.emerald.600);
    --tui-text-positive-hover: theme(colors.emerald.500);

    --tui-text-negative: theme(colors.red.600);
    --tui-text-negative-hover: theme(colors.red.500);

    /* Misc */
    --tui-border-normal: theme(colors.slate.200);
    --tui-border-hover: theme(colors.slate.300);
    --tui-border-focus: theme(colors.slate.500);

    --tui-service-autofill-background: theme(colors.amber.50);
    --tui-service-selection-background: theme(colors.blue.50);

    --tui-duration: theme(transitionDuration.DEFAULT);

    /* Shadows */
    --tui-shadow: theme(boxShadow.DEFAULT);
    --tui-shadow-small: theme(boxShadow.DEFAULT);
    --tui-shadow-small-hover: theme(boxShadow.md);
    --tui-shadow-medium: theme(boxShadow.md);
    --tui-shadow-popup: theme(boxShadow.xl);

    /* Sizes */
    --tui-height-xs: theme(spacing.6);
    --tui-height-s: theme(spacing.9);
    --tui-height-m: theme(spacing.12);
    --tui-height-l: theme(spacing.14);
    --tui-height-xl: theme(spacing.16);

    --tui-radius-xs: theme(borderRadius.md);
    --tui-radius-s: theme(borderRadius.lg);
    --tui-radius-m: theme(borderRadius.xl);
    --tui-radius-l: theme(borderRadius.3xl);
    --tui-radius-xl: theme(borderRadius.full);

    --tui-skeleton-radius: theme(borderRadius.xl);

    --tui-padding-s: theme(spacing[2.5]);
    --tui-padding-m: theme(spacing[3]);
    --tui-padding-l: theme(spacing[4]);

    /* Fonts */
    --tui-font-heading: 'Lexend', sans-serif;
    --tui-font-text: 'Lexend', sans-serif;

    --tui-font-heading-1: theme(fontWeight.medium) theme(fontSize.4xl)/theme(lineHeight.snug) var(--tui-font-heading);
    --tui-font-heading-2: theme(fontWeight.medium) theme(fontSize.3xl)/theme(lineHeight.snug) var(--tui-font-heading);
    --tui-font-heading-3: theme(fontWeight.normal) theme(fontSize.3xl)/theme(lineHeight.snug) var(--tui-font-heading);
    --tui-font-heading-4: theme(fontWeight.medium) theme(fontSize.2xl)/theme(lineHeight.normal) var(--tui-font-heading);
    --tui-font-heading-5: theme(fontWeight.medium) theme(fontSize.xl)/theme(lineHeight.normal) var(--tui-font-heading);
    --tui-font-heading-6: theme(fontWeight.normal) theme(fontSize.xl)/theme(lineHeight.normal) var(--tui-font-heading);

    --tui-font-text-xl: theme(fontWeight.normal) theme(fontSize.xl)/theme(lineHeight.snug) var(--tui-font-text);
    --tui-font-text-l: theme(fontWeight.normal) theme(fontSize.lg)/theme(lineHeight.snug) var(--tui-font-text);
    --tui-font-text-ui-l: theme(fontWeight.normal) theme(fontSize.lg)/theme(lineHeight.snug) var(--tui-font-text);
    --tui-font-text-m: theme(fontWeight.normal) theme(fontSize.base)/theme(lineHeight.normal) var(--tui-font-text);
    --tui-font-text-ui-m: theme(fontWeight.normal) theme(fontSize.base)/theme(lineHeight.normal) var(--tui-font-text);
    --tui-font-text-s: theme(fontWeight.normal) theme(fontSize.sm)/theme(lineHeight.normal) var(--tui-font-text);
    --tui-font-text-ui-s: theme(fontWeight.normal) theme(fontSize.sm)/theme(lineHeight.normal) var(--tui-font-text);
    --tui-font-text-xs: theme(fontWeight.normal) theme(fontSize.xs)/theme(lineHeight.normal) var(--tui-font-text);

    /* Overrides */

    // Inputs

    tui-input-inline {
        @apply flex #{!important};
    }

    [tuiWrapper][data-appearance='textfield'] {
        @apply shadow-none hover:shadow shadow-slate-600/10 #{!important};
    }

    [tuiWrapper][data-appearance='textfield']:not(._focused):after {
        color: var(--tui-border-normal);
    }

    [tuiAppearance][data-appearance=file] {
        border-color: var(--tui-border-normal);
    }

    // Stepper
    .t-marker_index {
        background-color: var(--tui-background-neutral-2) !important;
    }

    // Buttons

    [tuiButton],
    [tuiIconButton] {
        @apply font-normal #{!important};

        //     [tuiWrapper]:not(._no-hover) {
        //         @apply transition;

        //         &[data-appearance='primary'] {
        //             @apply hover:shadow active:shadow-lg;
        //         }

        //         &:hover,
        //         &:focus,
        //         &[data-state='hover'],
        //         &[data-state='focus'] {
        //             @apply ring-1 ring-slate-500/5;
        //         }

        //         &:active,
        //         &[data-state='active'] {
        //             @apply ring-2 ring-slate-600/10 brightness-95;
        //         }
        //     }
    }

    // Flat buttons

    // [tuiWrapper][data-appearance='flat'],
    // [tuiWrapper][data-appearance='outline'] {

    //     &:hover:not(._no-hover),
    //     &[data-state='hover'] {
    //         @media (hover: hover) {
    //             @apply bg-slate-200/30;
    //         }
    //     }

    //     &:active,
    //     &[data-state='active'] {
    //         @apply bg-slate-300/30;
    //     }

    // }

    // [tuiWrapper][data-appearance='flat-destructive'] {
    //     @apply text-red-700 #{!important};
    // }

    // [tuiWrapper][data-appearance='flat-destructive']:hover:not(._no-hover),
    // [tuiWrapper][data-appearance='flat-destructive'][data-state='hover'] {
    //     @media (hover: hover) {
    //         @apply bg-pixared-1/5;
    //     }
    // }

    // Secondary buttons
    // [tuiWrapper][data-appearance='secondary'] {
    //     @apply bg-blue-100/60;
    //     color: var(--tui-text-primary);
    // }

    // Outline buttons
    // [tuiWrapper][data-appearance='outline'] {

    //     @apply after:border-slate-200 text-slate-600;

    //     &:hover:not(._no-hover),
    //     &[data-state='hover'],
    //     &:active,
    //     &[data-state='active'] {
    //         @media (hover: hover) {
    //             @apply after:border-slate-200;
    //         }
    //     }

    // }

    [tuiAppearance][data-appearance=secondary] {
        background-color: var(--tui-background-neutral-2)
    }

    // Label
    tui-tag[data-status='label'],
    [tuitag][data-status='label'] {
        @apply bg-blue-100/60 break-all line-clamp-1 rounded-full uppercase;
    }

    tui-tag[data-size='l'],
    [tuitag][data-size='l'] {
        .t-tag {
            @apply px-3 #{!important};
        }
    }

    tui-tag[data-size='m'],
    [tuitag][data-size='m'] {
        .t-tag {
            @apply px-2 #{!important};
        }
    }


    // Island
    // tui-island {
    //     @apply rounded-xl shadow m-[2px];

    //     &::before {
    //         @apply border-0 #{!important};
    //     }
    // }

    // Card
    [tuiCardLarge][data-space][data-space=normal] {
        --t-radius: theme(borderRadius.lg);
        --t-padding: theme(spacing[5]);
        --t-gap: theme(spacing[4]);
    }

    // Surface
    [tuiSurface][data-surface=flat] {
        background-color: var(--tui-background-base)
    }

    // Table
    [tuiTable] {

        td,
        th {
            @apply border-l-0 border-r-0;
        }

        th {
            @apply border-t-0;
        }

        td {
            @apply py-1.5 bg-transparent border-solid border-b-0 border-t border-slate-200 #{!important};
        }
    }

    // Badge
    tui-badge {
        @apply w-fit;
    }

    // Block
    [tuiBlock] {
        @apply items-center;
    }


    // Checkbox block
    // tui-checkbox-block>.t-wrapper {
    //     @apply flex;
    // }

    // tui-checkbox-labeled>.t-wrapper {
    //     @apply w-[inherit];

    //     &>.t-content {
    //         @apply w-full;
    //     }
    // }

    // tui-checkbox-block>label .t-content {
    //     @apply w-full #{!important};
    // }

    // Radio block
    // tui-radio-block .t-wrapper {
    //     @apply w-full;
    // }

    // tui-radio-block>.t-wrapper>.t-label>.t-content {
    //     @apply w-full #{!important};
    // }

    // tui-radio-block>.t-wrapper {
    //     @apply w-full;
    //     @apply flex;
    // }

    // tui-radio-labeled>.t-wrapper {
    //     @apply w-[inherit];

    //     &>.t-content {
    //         @apply w-full;
    //         @apply flex;
    //     }
    // }

    // tui-radio-block>label .t-content {
    //     @apply w-full #{!important};
    // }

    // Tabs
    [tuiTab] {
        --tui-tab-margin: 36px !important;
    }

    [tuiTab]:not(.active) {
        @apply text-slate-500 hover:text-slate-600;
    }

    // Multi select
    tui-multi-select.hide-right-icon .t-textfield-icon {
        @apply hidden;
    }

    // Items with more
    tui-items-with-more {
        @apply gap-2;
    }

    // Hint
    tui-hint[data-appearance='warning'] {
        @apply bg-orange-600 text-white;
    }

    tui-hint[data-appearance='success'] {
        @apply bg-emerald-50 text-emerald-600 leading-tight shadow-lg shadow-emerald-500/30;
    }

    // Error
    tui-error {
        @apply text-base;
    }

    // Link 

    [tuiLink][data-appearance='primary'] {
        @apply text-blue-600 hover:text-blue-700 hover:underline #{!important};
    }

    [tuiLink][data-appearance='muted'] {
        @apply text-slate-600 hover:text-slate-700 #{!important};
    }

    [tuiLink] {
        & .t-content {
            @apply flex gap-0.5 items-center;
        }
    }

    .tui-link-active {
        @apply text-pixablue-1 #{!important};

        &[data-appearance='navigation'] {
            @apply bg-slate-100 #{!important};
        }
    }

    [tuiLink][data-appearance='navigation'] {
        @apply py-2 px-4 gap-3 transition-all rounded-xl hover:bg-slate-50 text-slate-600 flex items-center #{!important};

        & .t-icon_left {
            @apply transition-all text-sm #{!important};
        }

        &[data-collapsed='true'] .t-icon_left {
            @apply translate-x-3.5;
        }

        & .t-content {
            @apply flex-grow;
        }

        & .t-icon_right {
            @apply text-slate-300;
        }
    }

    tui-notification[data-collapsed='true'] {

        @apply justify-center transition-all #{!important};

        tui-svg {
            @apply mr-0 transition-all #{!important};
        }

        .t-content {
            @apply grow-0 shrink transition-all #{!important};
        }

    }

}

// CDK Drag & Drop
.cdk-drag-animating {
    @apply transition-all;
}

.cdk-drag-preview {
    @apply shadow-xl;
}