@layer components {
    .bg-action-dark {
        @apply bg-slate-900/50 hover:bg-slate-900/60 backdrop-blur-lg shadow-2xl rounded-xl border-t border-slate-600/50 transition-colors;
    }

    h1 {
        font: var(--tui-font-heading-1);
    }
    
    h2 {
        font: var(--tui-font-heading-2);
    }
    
    h3 {
        font: var(--tui-font-heading-3);
    }
    
    h4 {
        font: var(--tui-font-heading-4);
    }
    
    h5 {
        font: var(--tui-font-heading-5);
    }
    
    h6 {
        font: var(--tui-font-heading-6);
    }

    p {
        font: var(--tui-font-text-m);
    }

    h1, h2, h3, h4, h5, h6 {
        @apply tracking-tight;
    }

}